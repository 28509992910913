<template>
    <div id="page-provider-create">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="back_to">Back to System Admin</router-link>

                    <h1 class="heading">Create Provider</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="config" ref="config">
                        <app-loader v-if="processing" fixed-on="desktop"></app-loader>

                        <div class="board settings">
                            <app-error :value="error_message"></app-error>

                            <div class="info name">
                                <app-input
                                    v-model="provider.Name"

                                    label="Name"

                                    :required="true"

                                    :disabled="processing"
                                    :error="errors.fields.Name"

                                    @change="showNextError('Name')"
                                />
                            </div>

                            <div class="info name">
                                <app-input v-model="provider.DefaultSupportPhoneNumber" label="Support Phone Number" :error="errors.fields.DefaultSupportPhoneNumber" @change="showNextError('DefaultSupportPhoneNumber')" :disabled="processing"></app-input>
                                <app-input v-model="provider.TaxNumber"                 label="Tax Number" :error="errors.fields.TaxNumber" @change="showNextError('TaxNumber')" :disabled="processing"></app-input>
                            </div>

                            <h5>Contact Person</h5>
                            <div class="info">
                                <app-input
                                    v-model="provider.ContactPersonFirstName"

                                    label="First Name"

                                    :required="true"

                                    :disabled="processing"
                                    :error="errors.fields.ContactPersonFirstName"

                                    @change="showNextError('ContactPersonFirstName')"
                                />

                                <app-input
                                    v-model="provider.ContactPersonLastName"

                                    label="Last Name"

                                    :required="true"

                                    :disabled="processing"
                                    :error="errors.fields.ContactPersonLastName"

                                    @change="showNextError('ContactPersonLastName')"
                                />
                            </div>

                            <h5>Notifications Settings</h5>
                            <div class="info notifications">
                                <app-input v-model="provider.DefaultEmailFromName"     label="Default Email from Name" :error="errors.fields.DefaultEmailFromName" @change="showNextError('DefaultEmailFromName')" :disabled="processing"></app-input>
                                <app-input
                                    v-model="provider.DefaultEmailFromAddress"

                                    label="Default Email from Address"

                                    :required="true"

                                    :disabled="processing"
                                    :error="errors.fields.DefaultEmailFromAddress"

                                    @change="showNextError('DefaultEmailFromAddress')"
                                />
                            </div>
                            <div class="info notifications">
                                <app-input v-model="provider.DefaultSMSFromName"       label="Default SMS from Name" :error="errors.fields.DefaultSMSFromName" @change="showNextError('DefaultSMSFromName')" :disabled="processing"></app-input>
                                <app-input v-model="provider.DefaultSMSFromNumber"     label="Default SMS from Number" :error="errors.fields.DefaultSMSFromNumber" @change="showNextError('DefaultSMSFromNumber')" :disabled="processing"></app-input>
                            </div>

                            <h5>Logos</h5>
                            <div class="info logo">
                                <app-input
                                    v-model="provider.LogoWideURL"

                                    label="Wide Logo URL"

                                    :required="true"

                                    :maxlength="null"

                                    :disabled="processing"
                                    :error="errors.fields.LogoWideURL"

                                    @change="showNextError('LogoWideURL')"
                                />

                                <div class="logo-preview" v-if="loaded_images.LogoWideURL && !errors.fields.LogoWideURL">
                                    <img class="wide" :src="provider.LogoWideURL">
                                </div>
                            </div>
                            <div class="info logo">
                                <app-input
                                    v-model="provider.LogoIconURL"

                                    label="Small/Icon Logo URL"

                                    :required="true"

                                    :maxlength="null"

                                    :disabled="processing"
                                    :error="errors.fields.LogoIconURL"

                                    @change="showNextError('LogoIconURL')"
                                />

                                <div class="logo-preview" v-if="loaded_images.LogoIconURL && !errors.fields.LogoIconURL">
                                    <img class="small" :src="provider.LogoIconURL">
                                </div>
                            </div>
                        </div>

                        <div class="board features">
                            <app-checkbox-group
                                v-model="provider.Features"
                                :options="modules_user_without_services"

                                label="Features"

                                key-value="Name"
                                key-title="Title"

                                key-readonly="immutable"
                                key-disabled="immutable"

                                :disabled="processing"
                            />
                        </div>

                        <button class="btn btn-primary btn-small" @click="saveProvider" :disabled="processing">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appCheckboxGroup from '@/components/app-checkbox-group'

import ServiceProviderValidator from '@/validators/service-provider-validator'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,

        appInput,
        appCheckboxGroup,
    },

    data() {
        return {
            provider: {
                Name: '',

                Features: [],

                LogoWideURL: '',
                LogoIconURL: '',

                DefaultEmailFromName: '',
                DefaultEmailFromAddress: '',
                DefaultSMSFromName: '',
                DefaultSMSFromNumber: '',

                ContactPersonFirstName: '',
                ContactPersonLastName: '',
            },

            errors: {
                settings: null,
                fields: {},

                logos: {},
            },

            logo_promises: {},

            loaded_images: {},

            processing: false,

            referrer: null,
        }
    },
    methods: {
        saveProvider() {
            this.processing = true

            return this.validation()
                .then(() => {
                    const payload = {
                        ...this.provider,
                    }

                    return this.$store.dispatch('api_serviceprovider/AddServiceProvider', payload)
                        .then(provider => {
                            this.$router.push({ name: 'provider', params: {uuid: provider.UUID}})
                        })
                        .catch(error => {
                            if (error && error.response && error.response.data && error.response.data.err_field) {
                                console.log('field error', error.response.data.err_field)
                                this.errors.fields[error.response.data.err_field] = errMessage(error)
                            } else {
                                this.errors.settings = errMessage(error)
                            }

                            return Promise.reject(error)
                        })
                })
                .catch(() => {})
                .finally(() => {
                    this.processing = false
                })
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const values = {
                Name: {
                    value: this.provider.Name,
                    message: 'Please, enter valid provider name',
                },

                DefaultEmailFromName: {
                    value: this.provider.DefaultEmailFromName,
                    message: 'Please, enter valid name',
                },

                DefaultEmailFromAddress: {
                    value: this.provider.DefaultEmailFromAddress,
                    message: 'Please, enter valid email address',
                },

                DefaultSMSFromName: {
                    value: this.provider.DefaultSMSFromName,
                    message: 'Please, enter valid name',
                },

                DefaultSMSFromNumber: {
                    value: this.provider.DefaultSMSFromNumber,
                    message: 'Please, enter valid mobile number',
                },

                LogoWideURL: {
                    value: this.provider.LogoWideURL,
                    message: 'Please, enter valid wide logo URL',
                },

                LogoIconURL: {
                    value: this.provider.LogoIconURL,
                    message: 'Please, enter valid small logo URL',
                },

                ContactPersonFirstName: {
                    value: this.provider.ContactPersonFirstName,
                    message: 'Please, enter valid name',
                },
                ContactPersonLastName: {
                    value: this.provider.ContactPersonLastName,
                    message: 'Please, enter valid surname',
                },
            }

            for (const key in values) {
                if (ServiceProviderValidator.isRuleExists(key)) {
                    if (ServiceProviderValidator.isInvalid(key, values[key].value, values[key].message)) {
                        this.errors.fields[ key ] = ServiceProviderValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid ? this.validateLogos() : Promise.reject()
        },

        checkLogoAvailability(key) {
            const src = this.provider[key]

            this.loaded_images[key] = false

            if (!src) {
                this.logo_promises[key] = null
            } else {
                this.logo_promises[key] = new Promise((resolve, reject) => {
                    const img = new Image()

                    img.onload = () => {
                        if (src === this.provider[key]) {
                            this.loaded_images = {
                                ...this.loaded_images,
                                [key]: true,
                            }
                        }
                        resolve()
                    }

                    img.onerror = () => {
                        if (src === this.provider[key]) {
                            this.loaded_images = {
                                ...this.loaded_images,
                                [key]: false,
                            }
                        }
                        reject()
                    }

                    img.src = src
                })
            }
        },

        validateLogos() {
            const sizes = {
                LogoWideURL: 'wide',
                LogoIconURL: 'small'
            }

            return Promise.all(
                Object.keys(sizes).map( key => {
                    this.checkLogoAvailability(key)

                    if (this.logo_promises[key]) {
                        return this.logo_promises[key].catch(error => {
                            this.errors.fields[key] = `Something went wrong with the ${ sizes[key] } logo loading`

                            return Promise.reject()
                        })
                    } else {
                        this.errors.fields[key] = `Please enter ${ sizes[key] } logo`

                        return Promise.reject()
                    }
                })
            )
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }
        },
    },

    computed: {
        ...mapGetters([
            'modules_user',

            'current_spid',

            'module_types',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'providers'
                ? this.referrer
                : { name: 'providers' }
        },

        error_message() {
            return this.errors.settings
        },

        modules_user_without_services() {
            const new_modules = {}

            const module_names = Object.keys(this.modules_user)

            for (const i in module_names) {
                new_modules[module_names[i]] =  {
                    ...this.modules_user[module_names[i]]
                }

                const type = new_modules[module_names[i]].Type

                if (type == this.module_types.PRODUCT || type == this.module_types.TOOL) {
                    new_modules[module_names[i]].immutable = false
                } else {
                    new_modules[module_names[i]].immutable = true
                }
            }

            return new_modules
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-provider-create {
    margin-top: 24px;
    padding-bottom: 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .config {
        margin: 40px 0 0;
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-loader {
            border-radius: $border-radius-secondary;
        }

        .app-error {
            margin-bottom: 24px;
        }

        .board {
            margin-top: 40px;

            &:first-child {
                margin: 0;
            }

            &>h5 {
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 24px;
            }
        }

        .settings {
            .info {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 30px;

                .app-input {
                    width: calc(50% - 15px);
                    max-width: 370px;
                    margin-right: 30px;

                    &:last-child {
                        margin: 0;
                    }
                }

                .logo-preview {
                    display: flex;
                    align-items: center;

                    max-width: calc(50% - 15px);

                    height: 48px;
                    padding: 8px;

                    border-radius: $border-radius-primary;
                    border: solid 1px var(--color-input-border);

                    img {
                        display: block;

                        &.wide {
                            max-width: 100%;
                            height: auto;
                            max-height: 32px;
                            object-fit: contain;
                        }

                        &.small {
                            width: 100%;
                            max-width: 32px;

                            height: auto;
                            max-height: 32px;
                        }
                    }
                }
            }
        }

        .features {
            .app-checkbox-group {
                .options {
                    justify-content: flex-start;

                    .app-checkbox {
                        margin: 24px 48px 0 0;
                        white-space: nowrap;

                        &:last-child { margin-right: 0; }
                    }
                }
            }
        }

        &>.btn {
            margin-top: 30px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-provider-create {
        .config {
            .board {
                &>h5 {
                    font-size: 18px;
                    line-height: 20px;
                }
            }

            .settings {
                .info {
                    .app-input {
                        max-width: 258px;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-provider-create {
        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .config {
            margin: 32px 0 0;
            padding: 16px 8px;

            .board {
                margin-top: 32px;
                
                &>h5 {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .settings {
                .info {
                    flex-direction: column;
                    margin: 0 0 24px 0;

                    .app-input {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 0 24px 0;

                        &:nth-child(2) {
                            margin: 0;
                        }
                    }

                    .logo-preview {
                        max-width: 100%;
                    }
                }
            }

            .features {
                .app-checkbox-group {
                    .options {
                        justify-content: space-between;

                        .app-checkbox {
                            width: 35%;
                            min-width: 120px;
                            margin: 24px 0 0;
                        }
                    }
                }
            }

            &>.btn {
                margin-top: 24px;
            }
        }
    }
}
</style>