import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    Name: {
        name: 'Name',
        required: true,
        trim: true,
    },

    DefaultEmailFromName: {
        name: 'Default Email from Name',
        required: false,
    },

    DefaultEmailFromAddress: {
        name: 'Default Email from Address',
        required: true,
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    },

    DefaultSMSFromName: {
        name: 'Default SMS from Name',
        required: false,
    },

    DefaultSMSFromNumber: {
        name: 'Default SMS from Number',
        required: false,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,
                pattern: /[^\d]/g,
                replace: '',
            },
        ],
        format: /^\+?\d{9,12}$/,
    },

    LogoWideURL: {
        name: 'Wide Logo URL',
        required: true,
        format: /^(https?:\/)?\/\w+/i,
    },

    LogoIconURL: {
        name: 'Small/Icon Logo URL',
        required: true,
        format: /^(https?:\/)?\/\w+/i,
    },

    ContactPersonFirstName: {
        name: 'Contact Person First Name',
        required: true,
        format: /^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
    },
    ContactPersonLastName: {
        name: 'Contact Person Last Name',
        required: true,
        format: /^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
    },
})